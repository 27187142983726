import React, { useState, useEffect, useRef, useCallback } from 'react';
import { httpsCallable } from 'firebase/functions';
import { collection, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { functions } from '../firebase';
import InputForm from './InputForm';
import ResultScreen from './ResultScreen';
import LoadingAnimation from './LoadingAnimation';
import PreviewScreen from './PreviewScreen';

const SunoSongGenerator = ({ db }) => {
  const auth = getAuth();
  const STEPS = ['input', 'loading', 'preview', 'result'];
  const [currentStep, setCurrentStep] = useState(STEPS[0]);
  const [formData, setFormData] = useState({
    recipient: '',
    occasion: '',
    themes: '',
    genre: '',
    currentStep: 0
  });
  const [result, setResult] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const [error, setError] = useState(null);
  const [paymentError, setPaymentError] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [statusId, setStatusId] = useState(null);
  const pollingRef = useRef(null);

  const removeUndefined = (obj) => {
    return Object.fromEntries(
      Object.entries(obj).filter(([_, v]) => v !== undefined)
    );
  };

  const pollForResults = useCallback(async (id) => {
    const checkStatus = httpsCallable(functions, 'checkSongGenerationStatus');
    let attempts = 0;
    const maxAttempts = 60;
    let pollTimeout;
    let isPolling = true;
  
    const poll = async () => {
      if (!isPolling) return;
      if (attempts >= maxAttempts) {
        setError("Song generation is taking longer than expected. Please try again later.");
        setCurrentStep('input');
        return;
      }

      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          throw new Error('Please sign in to continue.');
        }

        await currentUser.getIdToken(true);

        console.log('Making status check call...', { 
          statusId: id, 
          currentStep,
          userId: currentUser.uid 
        });

        const response = await checkStatus({ statusId: id });
        
        if (!isPolling) return;
        
        if (response.data.status === 'completed') {
          console.log('Song completed:', response.data.result);
          setResult([response.data.result]);
          setCurrentStep('result');
        } else if (response.data.status === 'error') {
          throw new Error(response.data.error || 'An error occurred while generating the song.');
        } else if (response.data.status === 'processing') {
          if (response.data.initialResponse) {
            console.log('Got initial response:', response.data.initialResponse);
            setPreviewData({
              audioUrl: response.data.initialResponse.audio_url,
              title: response.data.initialResponse.title || 'Your Song',
              lyrics: response.data.initialResponse.lyric,
              tags: response.data.initialResponse.tags
            });
            setCurrentStep('preview');
          }
          attempts++;
          if (isPolling) {
            pollTimeout = setTimeout(poll, 20000);
          }
        }
      } catch (err) {
        console.error("Error checking status:", err);
        
        if (isPolling) {
          clearTimeout(pollTimeout);
          
          if (err.message.includes('authentication') || 
              err.code === 'unauthenticated' || 
              err.message.includes('Unauthenticated')) {
            setError("Please sign in again to continue.");
            setCurrentStep('input');
            window.location.href = '/';
          } else {
            setError(err.message || "An unexpected error occurred");
            setCurrentStep('input');
          }
        }
      }
    };
  
    pollingRef.current = () => {
      console.log('Cleaning up polling');
      isPolling = false;
      clearTimeout(pollTimeout);
    };
  
    await poll();
    return pollingRef.current;
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
      }
      setIsAuthReady(true);
    });
  
    return () => unsubscribe();
  }, [auth]);
  
  useEffect(() => {
    if (statusId && currentStep === 'loading' && auth.currentUser) {
      pollForResults(statusId);
    }
  }, [statusId, currentStep, auth.currentUser, pollForResults]);

  useEffect(() => {
    return () => {
      if (pollingRef.current) {
        pollingRef.current();
      }
    };
  }, []);

  const handleDownload = async () => {
    if (result && result[0] && result[0].audio_url) {
      try {
        const response = await fetch(result[0].audio_url);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = result[0].title ? `${result[0].title}.mp3` : 'generated_song.mp3';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (err) {
        console.error("Error downloading the song:", err);
        setError("Failed to download the song. Please try again.");
      }
    } else {
      setError("Audio not ready for download. Please try again.");
    }
  };

  const handleGenerate = async (paymentId) => {
    try {
      if (pollingRef.current) {
        pollingRef.current();
      }

      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('Please sign in to generate a song.');
      }

      setCurrentStep('loading');
      setError(null);
      setPaymentError(null);
      setResult(null);
      setPreviewData(null);
      setStatusId(null);

      const generateSong = httpsCallable(functions, 'generateAndSaveSong');

      await logUserInput();

      const { currentStep: _, ...cleanFormData } = formData;
      const songData = removeUndefined({
        make_instrumental: false,
        wait_audio: true,
        userId: currentUser.uid,
        formData: cleanFormData,
        paymentId
      });

      console.log('Sending song data:', songData);
      const response = await generateSong(songData);
      
      if (response.data && response.data.statusId) {
        setStatusId(response.data.statusId);
      } else {
        throw new Error('No status ID received from the server');
      }
    } catch (err) {
      console.error("Generation error:", err);
      
      if (err.message.includes('payment')) {
        setPaymentError(err.message);
      } else {
        setError(err.message || "An unexpected error occurred");
      }
      
      setCurrentStep('input');
    }
  };

  const logUserInput = async () => {
    try {
      const logData = removeUndefined({
        ...formData,
        timestamp: new Date()
      });
      
      await addDoc(collection(db, "user_inputs"), logData);
      console.log("User input logged successfully");
    } catch (err) {
      console.error("Error logging user input:", err);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 'loading':
        return (
          <div className="relative">
            <LoadingAnimation />
          </div>
        );
      case 'input':
        return (
          <InputForm
            onSubmit={handleGenerate}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 'preview':
        return (
          <PreviewScreen 
            audioUrl={previewData?.audioUrl}
            title={previewData?.title}
            lyrics={previewData?.lyrics}
            tags={previewData?.tags}
          />
        );
      case 'result':
        return (
          <ResultScreen 
            result={result} 
            onDownload={handleDownload}
          />
        );
      default:
        return null;
    }
  };

  if (!isAuthReady) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  return (
    <div className="w-full">
      {renderStep()}
      {error && (
        <div className="max-w-[640px] mx-auto px-4 mt-4">
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
          </div>
        </div>
      )}
      {paymentError && (
        <div className="max-w-[640px] mx-auto px-4 mt-4">
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <strong className="font-bold">Payment Error: </strong>
            <span className="block sm:inline">{paymentError}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SunoSongGenerator;