import React, { useState } from 'react';
import ResultCard from './ResultCard';
import { ArrowDownToLine, Send } from "lucide-react";

const ResultScreen = ({ result, onDownload }) => {
  const [downloadStatus, setDownloadStatus] = useState('');
  const [shareStatus, setShareStatus] = useState('');
  const songData = result && result[0];

  if (!songData || songData.status === 'error') {
    return (
      <div className="py-10">
        <h2 className="text-3xl font-bold text-secondary mb-5 max-w-[400px] mx-auto text-center">Es gab ein Problem bei der Erstellung deines Songs.</h2>
        <p className="text-center px-6 text-secondary">Bitte versuche es erneut oder kontaktiere den Support.</p>
      </div>
    );
  }

  const handleDownload = async () => {
    setDownloadStatus('Downloading...');
    await onDownload();
    setDownloadStatus('Download complete!');
    setTimeout(() => setDownloadStatus(''), 3000);
  };

  const handleShare = async () => {
    if (!songData.audio_url) return;

    if (!navigator.share) {
      alert("Sharing is not supported on this device/browser");
      return;
    }

    try {
      setShareStatus("Preparing...");
      
      const response = await fetch(songData.audio_url);
      const blob = await response.blob();
      
      const fileName = songData.title ? `${songData.title}.mp3` : "generated_song.mp3";
      const file = new File([blob], fileName, { type: "audio/mpeg" });

      const songTitle = songData.title || "einen Song";
      const shareText = `Ich habe "${songTitle}" mit tralala.me erstellt`;

      await navigator.share({
        title: songData.title || "Generated Song",
        text: shareText,
        files: [file],
        url: 'https://tralala.me'
      });

      setShareStatus("Teilen erfolgreich!");
      setTimeout(() => setShareStatus(''), 3000);
    } catch (err) {
      console.error("Error sharing the song:", err);
      if (err.name !== "AbortError") {
        setShareStatus("Sharing ist fehlgeschlagen");
      }
    }
  };

  return (
    <div className="py-20">
      <h2 className="text-3xl font-bold text-secondary mb-5 max-w-[400px] mx-auto text-center">Yeah! Dein Song ist bereit zum Anhören.</h2>
      <div className="px-5 max-w-[600px] mx-auto my-8">
        <ResultCard 
          title={songData.title || 'Dein Song'}
          audioUrl={songData.audio_url}
          lyrics={songData.lyric}
          tags={songData.tags ? songData.tags.split(' ') : []}
          isPreview={false}
          backgroundColor="#FF4D4D"
          textColor="#FFF3DD"
        />
      </div>
      <p className="text-center px-6 text-secondary">Alles erledigt! Dein Song steht jetzt zum Download bereit.</p>
      <div className="flex flex-wrap gap-4 justify-center mt-4">
        <button
          onClick={handleDownload} 
          className={`bg-dark-red text-white py-5 px-6 rounded-full font-bold inline-flex items-center gap-2 ${
            downloadStatus === 'Downloading...' ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#ff3333]'
          }`}
          disabled={downloadStatus === 'Downloading...'}
        >
          <ArrowDownToLine size={20} />
          <span>{downloadStatus || 'Song herunterladen'}</span>
        </button>
        <button
          onClick={handleShare}
          className={`bg-[#EBD2FE] text-tralala-purple py-5 px-6 rounded-full font-bold inline-flex items-center gap-2 ${
            shareStatus === 'Preparing...' ? 'opacity-50 cursor-not-allowed' : 'hover:bg-opacity-90'
          }`}
          disabled={shareStatus === 'Preparing...'}
        >
          <Send size={20} />
          <span>{shareStatus || 'Song teilen'}</span>
        </button>
      </div>
    </div>
  );
};

export default ResultScreen;