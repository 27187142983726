import React, { useEffect, useState, useCallback, useRef } from 'react';
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';

const PayPalButton = ({ onPaymentSuccess, onPaymentError, isDisabled = false }) => {
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [containerMounted, setContainerMounted] = useState(false);
  const buttonInstanceRef = useRef(null);
  const containerRef = useRef(null);
  const initializationTimerRef = useRef(null);
  const observerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const retryCountRef = useRef(0);
  const MAX_RETRIES = 3;
  const [sdkReady, setSdkReady] = useState(false);

  useEffect(() => {
    const checkAdBlocker = async () => {
      try {
        const response = await fetch('https://www.paypal.com/sdk/js', { 
          method: 'HEAD',
          mode: 'no-cors'
        });
        return response;
      } catch (error) {
        console.log('Ad blocker may be active');
        setError('Please disable your ad blocker to use PayPal checkout.');
      }
    };
    
    checkAdBlocker();
  }, []);

  const showError = useCallback((message, originalError) => {
    if (originalError?.message?.includes('Window closed') || 
        originalError?.message?.includes('Component closed')) {
      console.log('Payment window closed:', originalError);
      return;
    }
    console.error(message, originalError);
    setError(message);
    onPaymentError(new Error(message));
  }, [onPaymentError]);

  const loadPayPalScript = useCallback(async () => {
    try {
      setIsLoading(true);
      
      if (window.paypal?.Buttons) {
        setSdkReady(true);
        return;
      }

      const existingScripts = document.querySelectorAll(`script[src*="paypal.com/sdk/js"]`);
      existingScripts.forEach(script => script.remove());

      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&currency=EUR&disable-funding=credit,card,sepa&enable-funding=paypal&components=buttons&debug=false`;
      script.async = true;
      script.defer = true;

      const loadPromise = new Promise((resolve, reject) => {
        let timeoutId;

        script.onload = () => {
          clearTimeout(timeoutId);
          setTimeout(() => {
            if (window.paypal?.Buttons) {
              resolve();
            } else {
              reject(new Error('PayPal SDK loaded but Buttons not available'));
            }
          }, 100);
        };

        script.onerror = (error) => {
          clearTimeout(timeoutId);
          reject(new Error(`PayPal script failed to load: ${error.message || 'Unknown error'}`));
        };

        timeoutId = setTimeout(() => {
          reject(new Error('PayPal script load timeout'));
        }, 10000);
      });

      document.body.appendChild(script);
      await loadPromise;
      setSdkReady(true);
      setError(null);
    } catch (error) {
      console.error('PayPal script loading error:', error);
      if (retryCountRef.current < MAX_RETRIES) {
        retryCountRef.current += 1;
        console.log(`Retrying PayPal script load (${retryCountRef.current}/${MAX_RETRIES})...`);
        setTimeout(() => loadPayPalScript(), Math.pow(2, retryCountRef.current) * 1000);
      } else {
        showError('Failed to initialize PayPal. Please disable any ad blockers or try a different browser.', error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [showError]);

  const handlePaymentApproval = useCallback(async (data, actions) => {
    if (processing) return;
    
    setProcessing(true);
    setError(null);
    
    try {
      const orderId = data.orderID;
      console.log('Payment approved, verifying order:', orderId);

      const verifyPayment = httpsCallable(functions, 'verifyAndProcessPayment');
      const verificationResult = await verifyPayment({ orderID: orderId });

      if (verificationResult.data.success) {
        console.log('Payment verified successfully');
        onPaymentSuccess(verificationResult.data.paymentId);
      } else {
        throw new Error('Payment verification failed');
      }
    } catch (error) {
      console.error('Payment processing error:', error);
      
      if (!error.message.includes('Window closed') && !error.message.includes('Component closed')) {
        const errorMessage = error?.details || 
          'Payment verification failed. If payment was deducted, please contact support.';
        showError(errorMessage, error);
      }
    } finally {
      setProcessing(false);
    }
  }, [processing, onPaymentSuccess, showError]);

  const cleanupPayPalButton = useCallback(() => {
    if (buttonInstanceRef.current) {
      try {
        buttonInstanceRef.current.close();
      } catch (err) {
        console.log('PayPal button cleanup:', err);
      }
      buttonInstanceRef.current = null;
    }

    if (containerRef.current) {
      containerRef.current.innerHTML = '';
    }

    if (initializationTimerRef.current) {
      clearTimeout(initializationTimerRef.current);
      initializationTimerRef.current = null;
    }

    if (observerRef.current) {
      observerRef.current.disconnect();
    }
  }, []);

  const setupContainerObserver = useCallback(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (mutation.type === 'childList' && containerRef.current) {
          const isInDocument = document.body.contains(containerRef.current);
          if (isInDocument && !containerMounted) {
            setContainerMounted(true);
            return;
          }
        }
      }
    });

    if (containerRef.current) {
      observerRef.current.observe(document.body, {
        childList: true,
        subtree: true,
      });

      if (document.body.contains(containerRef.current)) {
        setContainerMounted(true);
      }
    }
  }, [containerMounted]);

  const initializePayPalButton = useCallback(async () => {
    if (!sdkReady || !window.paypal?.Buttons || !containerRef.current) {
      return;
    }

    try {
      setIsLoading(true);
      cleanupPayPalButton();

      if (containerRef.current) {
        containerRef.current.style.opacity = '1';
        containerRef.current.style.display = 'block';
        containerRef.current.innerHTML = '';
      }

      const buttons = window.paypal.Buttons({
        fundingSource: 'paypal',
        style: {
          layout: 'vertical',
          color: 'gold',
          shape: 'pill',
          label: 'paypal',
          height: 45
        },
        createOrder: (_, actions) => {
          setError(null);
          return actions.order.create({
            purchase_units: [{
              amount: {
                value: '2.90',
                currency_code: 'EUR'
              },
              description: 'Custom Song Generation'
            }],
            application_context: {
              shipping_preference: 'NO_SHIPPING',
              user_action: 'PAY_NOW'
            }
          }).catch(err => {
            console.error('Create order error:', err);
            throw err;
          });
        },
        onApprove: handlePaymentApproval,
        onCancel: () => {
          console.log('Payment cancelled by user');
          setProcessing(false);
          setError(null);
        },
        onError: (err) => {
          if (!err.message?.includes('Window closed') && 
              !err.message?.includes('Component closed') &&
              !err.message?.includes('logger')) {
            console.error('PayPal button error:', err);
            showError('An error occurred during payment. Please try again.', err);
          }
        }
      });

      if (!buttons.isEligible()) {
        console.log('PayPal Buttons not eligible');
        return;
      }

      let retries = 0;
      const maxRenderRetries = 3;
      
      const delay = (retryCount) => new Promise(resolve => setTimeout(resolve, 1000 * retryCount));
      
      while (retries < maxRenderRetries) {
        try {
          await buttons.render(containerRef.current);
          buttonInstanceRef.current = buttons;
          break;
        } catch (error) {
          retries++;
          if (retries === maxRenderRetries) {
            throw error;
          }
          await delay(retries);
        }
      }

    } catch (error) {
      if (!error.message?.includes('Window closed') && 
          !error.message?.includes('Component closed') &&
          !error.message?.includes('logger')) {
        showError('Failed to setup payment system. Please refresh the page.', error);
      }
    } finally {
      setIsLoading(false);
    }
  }, [sdkReady, handlePaymentApproval, showError, cleanupPayPalButton]);

  useEffect(() => {
    if (sdkReady && containerRef.current) {
      const timer = setTimeout(() => {
        initializePayPalButton();
      }, 100);
      
      return () => clearTimeout(timer);
    }
  }, [sdkReady, initializePayPalButton]);

  useEffect(() => {
    loadPayPalScript();
  }, [loadPayPalScript]);

  useEffect(() => {
    setupContainerObserver();
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [setupContainerObserver]);

  useEffect(() => {
    return () => {
      cleanupPayPalButton();
      setError(null);
      setContainerMounted(false);
    };
  }, [cleanupPayPalButton]);

  return (
    <div className="w-full max-w-md mx-auto">
      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
          <span className="block sm:inline">
            {error.includes('ad blocker') ? (
              <>
                {error} <br/>
                <small>The PayPal button may still work, but you might experience issues.</small>
              </>
            ) : error}
          </span>
        </div>
      )}
      <div className="min-h-[51px]">
        {processing ? (
          <div className="text-center flex items-center justify-center h-full">
            <p className="text-off-white">Processing payment...</p>
          </div>
        ) : (
          <>
            <div 
              ref={containerRef}
              id="paypal-button-container" 
              className={`transition-opacity duration-500 ${isDisabled ? 'opacity-50 pointer-events-none' : ''}`}
              style={{ minHeight: '45px' }}
            />
            {isLoading && (
              <div className="text-center py-2">
                <p className="text-off-white">PayPal wird geladen...</p>
              </div>
            )}
          </>
        )}
      </div>
      {!processing && error && (
        <button
          onClick={() => {
            setError(null);
            retryCountRef.current = 0;
            loadPayPalScript();
          }}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Retry Loading PayPal
        </button>
      )}
    </div>
  );
};

export default PayPalButton;