import React from 'react';
import { Link } from 'react-router-dom';

const StartingScreen = ({ onStart }) => {
  return (
    <div className="flex flex-col min-h-screen bg-[#FFF3DD]">
      <div className="flex-1 w-full flex flex-col pt-24 md:pt-16">
        <div className="flex flex-col items-center justify-center max-w-[640px] mx-auto text-center px-4">
          <h1 className="text-3xl lg:text-6xl font-semibold mb-4">
            <span className="text-[#FF4D4D]">Los geht's!</span>{' '}
            <span className="text-[#42052B]">In nur 4 kurzen Fragen zu deinem Song.</span>
          </h1>          
          <p className="text-[#42052B] text-lg mb-12">
            Im <strong>Menü</strong> unter <strong>"Meine Songs"</strong> findest du
            deine bereits erstellten Songs.
            Fragen? Check unser{' '}
            <Link to="/faq" className="underline">
              FAQ
            </Link>
            .
          </p>
        </div>
      </div>

      <div className="w-full py-2 fixed bottom-0 left-0 z-[10] bg-[#FFF3DD]">
        <div className="max-w-[640px] mx-auto px-5">
          <div className="py-2 lg:py-8">
            <button
              onClick={onStart}
              className="w-full px-12 py-4 text-white rounded-full font-bold bg-[#FF4D4D] hover:bg-[#ff3333] transition-colors"
            >
              Starten
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartingScreen;