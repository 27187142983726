import React, { useEffect } from 'react';
import Lottie from 'lottie-react';
import animationData from '../assets/Loading-Animation-2.json';

const LoadingAnimation = () => {
  useEffect(() => {
    // Cleanup function
    return () => {
      // Any cleanup needed for the animation
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 w-full h-full flex flex-col justify-center items-center z-50 bg-off-white bg-opacity-90">
      <h2 className="text-3xl font-bold text-secondary mb-5 max-w-[400px] mx-auto text-center">
        Dein Song wird jetzt für dich erstellt.
      </h2>
      <div className="w-64 h-64 mb-5 relative">
        <Lottie
          animationData={animationData}
          loop={true}
          autoplay={true}
          rendererSettings={{
            preserveAspectRatio: 'xMidYMid slice'
          }}
        />
      </div>
      <p className="text-center px-5 text-lg text-[#4B002F]">
        In ein paar Minuten ist dein Song fertig. Du kannst das Fenster schließen und deinen Song später im{' '}
        <strong>Menü</strong> unter "<strong>Meine Songs</strong>" anhören und herunterladen!
      </p>
    </div>
  );
};

export default LoadingAnimation;