import React from 'react';
import PayPalButton from "./PayPalButton";

const SummaryStep = ({ 
  questions,
  formData,
  onBack,
  onPaymentSuccess,
  onPaymentError,
  isStepComplete 
}) => {
  return (
    <div className="w-full max-w-md text-left mx-auto">
      <div className="max-w-md">
        {questions.filter(q => q.type !== 'start' && q.type !== 'summary').map((q) => (
          <div key={q.id} className="mb-5">
            <h3 className="text-base font-medium text-[#EBD2FE] opacity-80 mb-1">
              {q.question}
            </h3>
            <p className="text-xl text-[#FFF3DD] font-semibold">
              {formData[q.id]}
            </p>
          </div>
        ))}
      </div>

      {/* Price information */}
      <div className="my-8 text-left border-t border-light-purple pt-4">
        <p className="text-off-white text-2xl font-bold mb-2 mt-4 flex justify-between">
          <span>Gesamtbetrag:</span>
          <span>2,90 €</span>
        </p>
        <p className="text-off-white text-left text-sm opacity-80 mb-12">
        Jetzt sicher mit PayPal bezahlen. <br />
        Nach dem Bezahlvorgang wird dein Song für dich erstellt.
        </p>
        <div className="flex flex-col items-center">
          <PayPalButton
            onPaymentSuccess={onPaymentSuccess}
            onPaymentError={onPaymentError}
            isDisabled={!isStepComplete}
          />
          <button
            onClick={onBack}
            className="mt-6 px-7 rounded-xl font-bold text-center text-[#FFF3DD] hover:text-[#EBD2FE] transition-colors"
          >
            Zurück
          </button>
        </div>
      </div>
    </div>
  );
};

export default SummaryStep;