import React from 'react';
import { Link } from 'react-router-dom';
import HeroLogo from '../assets/HeroLogo';

const Footer = () => {
  return (
    <footer className="bg-[#4B002F] text-[#FFF3DD] pb-40 py-16 px-8 lg:py-24">
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row lg:justify-between items-start lg:space-x-8">
        <div className="mb-8 lg:mb-0 lg:w-1/4 flex flex-col items-start">
          <div className="w-full max-w-[200px] h-[60px] relative mb-6">
            <HeroLogo className="w-full h-full object-contain" alt="TRALALA" />
          </div>
        </div>
        
        <div className="flex flex-col space-y-2 mb-8 lg:mb-0 lg:w-1/4 items-start">
          <Link to="/#Beispiele" className="hover:underline">Beispiele</Link>
          <Link to="/#So funktioniert's" className="hover:underline">So funktioniert's</Link>
          <Link to="/#Anlässe" className="hover:underline">Anlässe</Link>
          <Link to="/#FAQ" className="hover:underline">FAQ</Link>
        </div>
        
        <div className="flex flex-col space-y-2 mb-8 lg:mb-0 lg:w-1/4 items-start">
          <Link to="/kontakt" className="hover:underline">Kontakt</Link>
          <Link to="/imprint" className="hover:underline">Imprint</Link>
          <Link to="/privacy" className="hover:underline">Privatsphäre</Link>
        </div>

        <div className="flex flex-col space-y-2 mb-8 lg:mb-0 lg:w-1/4 items-start">
          <div className="flex space-x-4">
            <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer" aria-label="TikTok">
              <img 
                src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/TikTok%201-jM8sJdqekgMfOkL58sCPxaekCFeCXh.png" 
                alt="TikTok" 
                width={24} 
                height={24} 
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
