/**
 * Scrolls to a specified section smoothly.
 * @param {string} sectionId - The ID of the section to scroll to.
 */
export const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  /**
   * Extracts the hash from a URL.
   * @param {string} url - The URL to extract the hash from.
   * @returns {string} The extracted hash without the '#' symbol, or an empty string if no hash is present.
   */
  export const getHashFromUrl = (url) => {
    const hashIndex = url.indexOf('#');
    return hashIndex !== -1 ? url.substring(hashIndex + 1) : '';
  };