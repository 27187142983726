import React from 'react';

const ProgressBarIllu = () => (
<svg width="163" height="32" viewBox="0 0 163 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 17L161 17" stroke="white" strokeWidth="4" strokeLinecap="round"/>
<path d="M2 17L62 17" stroke="#4B002F" strokeWidth="4" strokeLinecap="round"/>
<circle cx="62" cy="16" r="16" fill="#4B002F"/>
<path d="M71.7857 16.2778C71.7857 17.4775 56.7794 25.8781 56.1857 25.2844C55.5921 24.6908 55.2411 8.21571 56.1857 7.2711C57.1303 6.3265 71.7857 15.0781 71.7857 16.2778Z" fill="#FFF3DD"/>
</svg>
);

export default ProgressBarIllu;
