import React, { useEffect, useRef } from "react";
import SummaryStep from "./SummaryStep";
import StartingScreen from "./StartingScreen";

const InputForm = ({ onSubmit, formData, setFormData }) => {
  const questions = [
    {
      id: "start",
      type: "start",
    },
    {
      id: "recipient",
      question: "Wen möchtest du mit einem Song überraschen?",
      description: "Der Name oder die Beziehung zur Person",
      placeholder: "z.B. Meine Mutter, beste Freundin Sarah",
    },
    {
      id: "occasion",
      question: "Was feiern wir?",
      description: "Der besondere Anlass für den Song",
      placeholder: "z.B. 49. Geburtstag, Hochzeit, Abschluss",
    },
    {
      id: "themes",
      question: "Möchtest du etwas besonderes sagen?",
      description: "Persönliche Details machen den Song einzigartig",
      placeholder: "z.B. Lieblingssprüche, Insider, Witze",
    },
    {
      id: "genre",
      question: "Welcher Musikstil solls werden?",
      description: "Die Musikrichtung für deinen personalisierten Song",
      placeholder: "z.B. Pop, Schlager, Rock, Hip-Hop",
    },
    {
      id: "summary",
      question: "Das klingt super! Stimmt alles?",
      type: "summary",
    },
  ];

  const currentQuestion = questions[formData.currentStep];
  const isSummaryStep = currentQuestion.type === "summary";
  const isFirstStep = false;
  const isStepComplete =
    formData.currentStep === 0 ||
    isSummaryStep ||
    formData[currentQuestion.id] !== "";

  const formRef = useRef(null);
  const navigationRef = useRef(null);

  const handleNext = () => {
    if (formData.currentStep < questions.length - 1) {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      
      window.scrollTo(0, 0);
      
      setFormData({ ...formData, currentStep: formData.currentStep + 1 });
    }
  };

  const handleBack = () => {
    if (formData.currentStep === 1) {
      setFormData({ ...formData, currentStep: 0 });
    } else if (formData.currentStep > 0) {
      setFormData({ ...formData, currentStep: formData.currentStep - 1 });
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleKeyPress = (e) => {
    if (
      e.key === "Enter" &&
      formData[questions[formData.currentStep].id] !== ""
    ) {
      e.preventDefault();
      e.target.blur();
      handleNext();
    }
  };

  const handlePaymentSuccess = (paymentId) => {
    onSubmit(paymentId);
  };

  const handlePaymentError = (error) => {
    console.error('Payment failed:', error);
  };

  const renderInput = () => {
    if (currentQuestion.type === "start") {
      return (
        <StartingScreen 
          onStart={() => handleNext()} 
        />
      );
    }
    
    if (currentQuestion.type === "summary") {
      return (
        <SummaryStep
          questions={questions.slice(1, -1)}
          formData={formData}
          onBack={handleBack}
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentError={handlePaymentError}
          isStepComplete={isStepComplete}
        />
      );
    }
    
    return (
      <input
        id={currentQuestion.id}
        type="text"
        value={formData[currentQuestion.id]}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder={currentQuestion.placeholder}
        className="w-full p-4 border-2 border-transparent rounded-2xl text-lg bg-[#EBD2FE] text-center text-[#42052B] placeholder-[#42052B] placeholder-opacity-50 font-medium placeholder:italic focus:outline-none focus:ring-0 focus:border-[#4B002F]"
      />
    );
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.visualViewport) {
        const currentHeight = window.innerHeight;
        const viewportHeight = window.visualViewport.height;
        const keyboardHeight = currentHeight - viewportHeight;

        if (formRef.current && navigationRef.current) {
          formRef.current.style.height = `${viewportHeight}px`;

          if ("ontouchstart" in window) {
            navigationRef.current.style.bottom = `${keyboardHeight}px`;
          } else {
            navigationRef.current.style.transform = `translateY(-${keyboardHeight}px)`;
          }
        }
      }
    };

    window.visualViewport.addEventListener("resize", handleResize);
    window.visualViewport.addEventListener("scroll", handleResize);
    window.addEventListener("resize", handleResize);

    return () => {
      window.visualViewport.removeEventListener("resize", handleResize);
      window.visualViewport.removeEventListener("scroll", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [formData.currentStep]);

  useEffect(() => {
    // Set background color based on summary step
    const root = document.documentElement;
    const body = document.body;
    
    if (isSummaryStep) {
      root.style.backgroundColor = "#42052B";
      body.style.backgroundColor = "#42052B";
    } else {
      root.style.backgroundColor = "#FFF3DD";
      body.style.backgroundColor = "#FFF3DD";
    }
    
    body.style.overflow = isSummaryStep ? "unset" : "hidden";

    // Reset styles when component unmounts
    return () => {
      root.style.backgroundColor = "#FFF3DD";
      body.style.backgroundColor = "#FFF3DD";
      body.style.overflow = "unset";
    };
  }, [isSummaryStep]);

  return (
    <div 
      className={`flex flex-col min-h-screen ${
        isSummaryStep ? "bg-[#42052B]" : "bg-[#FFF3DD]"
      }`} 
      ref={formRef}
    >
      <div className={`flex-1 w-full flex flex-col md:justify-center pt-24 md:pt-16 pb-32 ${
        isSummaryStep ? "bg-[#42052B]" : "bg-[#FFF3DD]"
      }`}>
        <div className={`w-full max-w-[640px] mx-auto px-4 ${
          isSummaryStep ? "bg-[#42052B]" : ""
        }`}>
          {currentQuestion.type !== "start" && (
            <>
              <h2
                className={`text-3xl w-full font-semibold mb-2 lg:text-6xl ${
                  isSummaryStep
                    ? "text-left max-w-md text-[#FFF3DD] mx-auto"
                    : "text-center text-[#42052B]"
                }`}
              >
                {currentQuestion.question}
              </h2>
              {currentQuestion.description && (
                <p className={`text-center mb-8 ${
                  isSummaryStep ? "text-[#FFF3DD]" : "text-grey"
                }`}>
                  {currentQuestion.description}
                </p>
              )}
            </>
          )}
          {renderInput()}
        </div>
      </div>
      {!isSummaryStep && currentQuestion.type !== "start" && (
        <div
          className={`w-full py-2 fixed bottom-0 left-0 z-[10] bg-[#FFF3DD]`}
          ref={navigationRef}
        >
          <div className="max-w-[640px] z-[10] mx-auto px-5">
            <div className="flex justify-between mb-5">
              {questions.slice(1, -1).map((_, index) => (
                <div
                  key={index}
                  className={`flex-grow h-1 rounded-sm mx-0.5 ${
                    index === formData.currentStep - 1
                      ? "bg-[#42052B]"
                      : index < formData.currentStep - 1
                      ? "bg-[#42052B]"
                      : "bg-[#EBD2FE]"
                  }`}
                />
              ))}
            </div>
            <div className="flex justify-between py-2 lg:py-8">
              <button
                onClick={handleBack}
                disabled={isFirstStep}
                className={`flex items-center px-7 py-4 rounded-xl font-bold ${
                  isFirstStep ? "opacity-50 cursor-not-allowed" : ""
                } text-[#42052B]`}
              >
                Zurück
              </button>
              <button
                onClick={handleNext}
                disabled={!isStepComplete}
                className={`px-12 py-4 text-white rounded-full font-bold ${
                  isStepComplete
                    ? "bg-[#FF4D4D] hover:bg-[#ff3333]"
                    : "opacity-50 bg-[#FF4D4D] cursor-not-allowed"
                }`}
              >
                Weiter
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};


export default InputForm;