import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";

// Reuse FAQ items from LandingPage
const faqItems = [
  {
    question: "Für welchen Anlass ist ein Tralala-Song das geeignete Geschenk?",
    answer:
      "Es gibt immer einen Grund zu Feiern. Einige Beispiele, bei denen ein personalisierter Song ein tolles Geschenk sein kann: Geburtstag, Schwangerschaft, Hochzeit, Urlaubsgruß, Glückwünsche, Jahrestag, Dankeschön, Gute Besserung, Abschluss, Taufe, Jubiläum, Promotion, Umzug, …",
  },
  {
    question: "Wie funktioniert Tralala?",
    answer:
      "Ganz einfach! Du beantwortest ein paar simple Fragen: Für wen ist der Song? Was feiern wir? Möchtest du etwas besonderes sagen? Welcher Musikstil soll der Song werden? Mit diesen Infos erstellen wir den Song und du kannst ihn nach nur wenigen Minuten anhören und herunterladen.",
  },
  {
    question: "Was kostet Tralala?",
    answer:
      "Unser Preis von 2,90€ pro Song spiegelt die Einzigartigkeit und den persönlichen Wert jedes individuell erstellten Liedes wider. Dieser faire Preis ermöglicht es uns, hochwertige, maßgeschneiderte Songs zu produzieren und gleichzeitig unseren Service für jeden erschwinglich zu halten. Mit Tralala erhältst du ein einzigartiges, persönliches Geschenk, das weit über den materiellen Wert hinausgeht und unvergessliche Erinnerungen schafft.",
  },
  {
    question: "Was mache ich, wenn mir der erstellte Song nicht gefällt?",
    answer:
      "Wir arbeiten derzeit an einem Prozess, um solche Situationen bestmöglich zu handhaben. Unser Ziel ist es, dass jeder Kunde mit seinem personalisierten Song zufrieden ist. Wenn du Bedenken hast, kontaktiere bitte unseren Kundenservice. Wir werden unser Bestes tun, um eine Lösung zu finden, die dich glücklich macht.",
  },
];

const FAQ = ({ question, answer, isOpen, toggleFAQ }) => {
  return (
    <div className="border-b border-tralala-purple/20 py-4">
      <button
        className="flex justify-between items-center w-full text-left text-tralala-purple hover:text-tralala-purple/80 transition-colors duration-200"
        onClick={toggleFAQ}
      >
        <span className="text-lg md:text-xl font-semibold max-w-[70ch] break-words">
          {question}
        </span>
        <span className={`transform transition-transform duration-200`}>
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </span>
      </button>
      {isOpen && (
        <div className="mt-2 text-tralala-purple/80 text-base md:text-lg">
          {answer}
        </div>
      )}
    </div>
  );
};

const HelpPage = () => {
  const [faqStates, setFaqStates] = useState(
    Array(faqItems.length).fill(false)
  );

  const toggleFAQ = (index) => {
    setFaqStates((prev) => {
      const newStates = [...prev];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  return (
    <div className="min-h-screen bg-tralala-bg pt-32 pb-20">
      <div className="px-4">
        <div className="max-w-[calc(100vw-3rem)] md:max-w-[calc(100vw-16rem)] lg:max-w-[60%] mx-auto">
          <h1 className="text-[2rem] md:text-[3rem] font-medium mb-10 md:mb-14 leading-[2.1rem] md:leading-[3.15rem] tracking-[-0.01em] text-tralala-purple">
            Häufig gestellte Fragen
          </h1>
          <div className="w-full">
            {faqItems.map((item, index) => (
              <FAQ
                key={index}
                question={item.question}
                answer={item.answer}
                isOpen={faqStates[index]}
                toggleFAQ={() => toggleFAQ(index)}
              />
            ))}
          </div>
        </div>
        <div className="mt-20 max-w-[calc(100vw-3rem)] md:max-w-[calc(100vw-16rem)] lg:max-w-[60%] mx-auto">
          <h2 className="text-[1.5rem] md:text-[2rem] font-medium mb-10 md:mb-14 leading-[2.1rem] md:leading-[3.15rem] tracking-[-0.01em] text-tralala-purple">
            Immernoch Fragen?
            <br />
            Schreib uns einfach eine E-Mail an{" "}
            <a href="mailto:hilfe@tralala.de" className="text-tralala-red hover:text-tralala-red/80 transition-colors duration-200">hilfe@tralala.de</a>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default HelpPage;
