import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from '../auth/AuthContext';
import ResultCard from "./ResultCard";
import { useNavigate } from "react-router-dom";
import { ArrowDownToLine, Send } from "lucide-react";

const MySongsPage = () => {
  const { user } = useAuth();
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [downloadStatus, setDownloadStatus] = useState({});
  const [shareStatus, setShareStatus] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchSongs(user.uid);
    }
  }, [user]);

  const isValidAudioUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      console.warn("Invalid audio URL:", url);
      return false;
    }
  };

  const fetchSongs = async (userId) => {
    try {
      const q = query(
        collection(db, "songGenerationStatus"),
        where("userId", "==", userId),
        where("status", "==", "completed"),
        orderBy("completionTime", "desc")
      );

      const querySnapshot = await getDocs(q);
      const songsData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const audioUrl =
          data.result && isValidAudioUrl(data.result.audio_url)
            ? data.result.audio_url
            : null;

        return {
          id: doc.id,
          ...data.result,
          completionTime: data.completionTime,
          audio_url: audioUrl,
        };
      });

      setSongs(songsData);
    } catch (err) {
      console.error("Error fetching songs:", err);
      setError("Failed to fetch songs. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = async (song) => {
    if (song.audio_url) {
      try {
        setDownloadStatus((prev) => ({ ...prev, [song.id]: "Downloading..." }));
        const response = await fetch(song.audio_url);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = song.title ? `${song.title}.mp3` : "generated_song.mp3";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        setDownloadStatus((prev) => ({
          ...prev,
          [song.id]: "Download complete!",
        }));
        setTimeout(
          () => setDownloadStatus((prev) => ({ ...prev, [song.id]: "" })),
          3000
        );
      } catch (err) {
        console.error("Error downloading the song:", err);
        setDownloadStatus((prev) => ({
          ...prev,
          [song.id]: "Download failed",
        }));
      }
    }
  };

  const handleShare = async (song) => {
    if (!song.audio_url) return;

    if (!navigator.share) {
      alert("Sharing is not supported on this device/browser");
      return;
    }

    try {
      setShareStatus((prev) => ({ ...prev, [song.id]: "Preparing..." }));
      
      const response = await fetch(song.audio_url);
      const blob = await response.blob();
      
      const fileName = song.title ? `${song.title}.mp3` : "generated_song.mp3";
      const file = new File([blob], fileName, { type: "audio/mpeg" });

      const songTitle = song.title || "einen Song";
      const shareText = `Ich habe "${songTitle}" mit tralala.me erstellt`;

      await navigator.share({
        title: song.title || "Generated Song",
        text: shareText,
        files: [file],
        url: 'https://tralala.me'
      });

      setShareStatus((prev) => ({
        ...prev,
        [song.id]: "Teilen erfolgreich!",
      }));
      setTimeout(
        () => setShareStatus((prev) => ({ ...prev, [song.id]: "" })),
        3000
      );
    } catch (err) {
      console.error("Error sharing the song:", err);
      if (err.name !== "AbortError") {
        setShareStatus((prev) => ({
          ...prev,
          [song.id]: "Sharing ist fehlgeschlagen",
        }));
      }
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-tralala-bg">
        <div className="text-center space-y-4">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-tralala-red border-t-transparent mx-auto"></div>
          <p className="text-tralala-purple text-lg">Einen Moment bitte...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-tralala-bg">
        <div className="bg-red-100 border border-red-400 text-red-700 px-6 py-4 rounded-lg mb-4">
          {error}
        </div>
        <button
          onClick={() => navigate("/generator")}
          className="bg-tralala-red text-[#FFF3DD] py-3 px-6 rounded-full font-bold hover:bg-opacity-90 transition-colors"
        >
          Zurück zum Generator
        </button>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 pt-16 bg-tralala-bg min-h-screen">
      <h1 className="text-3xl font-bold mb-6 text-center text-tralala-purple">Meine Songs</h1>
      {songs.length === 0 ? (
        <div className="flex flex-col items-center">
          <p className="text-center mb-4 text-tralala-purple">
            Du hast noch keine Songs generiert.
          </p>
          <button
            onClick={() => navigate("/generator")}
            className="bg-tralala-red text-[#FFF3DD] py-3 px-6 rounded-full font-bold hover:bg-opacity-90 transition-colors"
          >
            Erstelle deinen ersten Song
          </button>
        </div>
      ) : (
        <div className="flex flex-col gap-16 max-w-[480px] mx-auto">
          {songs.map((song) => (
            <div key={song.id} className="flex flex-col">
              <ResultCard
                id={song.id}
                title={song.title || "Untitled Song"}
                audioUrl={song.audio_url}
                lyrics={song.lyric}
                tags={song.tags ? song.tags.split(" ") : []}
                isPreview={false}
                backgroundColor="#FF4D4D"
                textColor="#FFF3DD"
              />
              <div className="flex flex-wrap gap-4 mt-4">
                <button
                  onClick={() => handleDownload(song)}
                  className={`bg-tralala-red text-[#FFF3DD] py-5 px-6 rounded-full font-bold inline-flex items-center gap-2 transition-all ${
                    downloadStatus[song.id] === "Downloading..."
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-opacity-90"
                  }`}
                  disabled={downloadStatus[song.id] === "Downloading..."}
                >
                  <ArrowDownToLine size={20} />
                  <span>{downloadStatus[song.id] || "Song herunterladen"}</span>
                </button>
                <button
                  onClick={() => handleShare(song)}
                  className={`bg-[#EBD2FE] text-tralala-purple py-5 px-6 rounded-full font-bold inline-flex items-center gap-2 transition-all ${
                    shareStatus[song.id] === "Preparing..."
                      ? "opacity-50 cursor-not-allowed"
                      : "hover:bg-opacity-90"
                  }`}
                  disabled={shareStatus[song.id] === "Preparing..."}
                >
                  <Send size={20} />
                  <span>{shareStatus[song.id] || "Song teilen"}</span>
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MySongsPage;