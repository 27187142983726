import React from 'react';
import ResultCard from './ResultCard';

const PreviewScreen = ({ audioUrl, title, lyrics, tags }) => {
  // Transform tags string into array like in ResultScreen
  const processedTags = typeof tags === 'string' ? tags.split(' ') : Array.isArray(tags) ? tags : [];
  
  return (
    <div className="py-20">
      <h2 className="text-3xl font-bold text-secondary mb-5 max-w-[400px] mx-auto text-center">Yeah! Dein Song ist bereit zum Anhören.</h2>
      <div className="px-5 max-w-[600px] mx-auto my-8">
        <ResultCard 
          title={title || 'Dein Song'}
          audioUrl={audioUrl}
          lyrics={lyrics}
          tags={processedTags}
          backgroundColor="#FF4D4D"
          textColor="#FFF3DD"
          isPreview={true}
        />
      </div>
      <p className="text-center px-6 text-secondary">Der Download wird gleich verfügbar sein. Bitte noch einen Moment Geduld, wir kümmern uns darum.</p>
      <button 
        className="block bg-dark-red text-white py-5 px-6 rounded-full font-bold mt-4 mx-auto w-fit opacity-50 cursor-not-allowed"
        disabled
      >
        Download wird vorbereitet...
      </button>
    </div>
  );
};

export default PreviewScreen;