import React, { useState, useRef, useEffect } from "react";
import { Play, Pause, AlertTriangle } from "lucide-react";

const TagPill = ({ tag }) => (
  <span className="bg-background text-secondary px-3 py-1 rounded-full text-xs font-medium whitespace-nowrap">
    {tag.charAt(0).toUpperCase() + tag.slice(1).toLowerCase()}
  </span>
);

const ResultCard = ({ 
  id,
  title, 
  audioUrl, 
  backgroundColor, 
  textColor, 
  isPreview = false, 
  lyrics = '',
  tags = [] // Default empty array
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const audioRef = useRef(null);
  const progressRef = useRef(null);

  // Ensure tags is always an array
  const safeTags = Array.isArray(tags) ? tags : [];

  useEffect(() => {
    const audio = new Audio(audioUrl);
    audioRef.current = audio;

    const setAudioData = () => {
      setDuration(audio.duration);
      setIsLoading(false);
      setError(null);
    };

    const handleTimeUpdate = () => setCurrentTime(audio.currentTime);

    const handleError = () => {
      let errorMessage = 'Failed to load audio';
      if (audio.error) {
        switch (audio.error.code) {
          case 1: errorMessage = 'The fetching of the audio file was aborted'; break;
          case 2: errorMessage = 'Network error occurred while loading the audio file'; break;
          case 3: errorMessage = 'Error occurred while decoding the audio file'; break;
          case 4: errorMessage = 'Audio file or resource not found'; break;
        }
      }
      setError(errorMessage);
      setIsLoading(false);
    };

    const handleCanPlayThrough = () => {
      setIsLoading(false);
      setError(null);
    };

    audio.addEventListener("loadedmetadata", setAudioData);
    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("error", handleError);
    audio.addEventListener("canplaythrough", handleCanPlayThrough);

    return () => {
      audio.removeEventListener("loadedmetadata", setAudioData);
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("error", handleError);
      audio.removeEventListener("canplaythrough", handleCanPlayThrough);
      audio.pause();
      audio.src = "";
    };
  }, [audioUrl]);

  const togglePlay = () => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play().catch(e => setError(`Failed to play audio: ${e.message}`));
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const handleProgressClick = (event) => {
    const audio = audioRef.current;
    if (duration > 0) {
      const progressBar = progressRef.current;
      const clickPosition =
        (event.clientX - progressBar.getBoundingClientRect().left) /
        progressBar.offsetWidth;
      const newTime = clickPosition * duration;
      if (isFinite(newTime) && newTime >= 0 && newTime <= duration) {
        audio.currentTime = newTime;
        setCurrentTime(newTime);
      }
    }
  };

  const formatTime = (time) => {
    if (isNaN(time) || !isFinite(time)) return "0:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div
      className="w-full rounded-3xl p-6"
      style={{ backgroundColor, color: textColor }}
    >
      <h2 className="text-2xl font-bold pb-0 m-0 text-left">{title}</h2>
      <div className="flex flex-wrap gap-2 mb-4 mt-2">
        {safeTags.map((tag, index) => (
          <TagPill key={index} tag={tag} />
        ))}
      </div>
      <div className="flex items-center">
        <button
          onClick={togglePlay}
          className="bg-background text-text w-12 h-12 rounded-full flex items-center justify-center text-lg mr-4 hover:opacity-90 disabled:opacity-50"
          disabled={isLoading || error}
        >
          {isPlaying ? (
            <Pause size={24} fill="currentColor" stroke="none" />
          ) : (
            <Play size={24} fill="currentColor" stroke="none" />
          )}
        </button>
        <div
          className="flex-grow h-1.5 bg-background rounded-full cursor-pointer"
          ref={progressRef}
          onClick={handleProgressClick}
        >
          <div
            className="h-full bg-secondary rounded-full transition-all duration-100 ease-linear"
            style={{
              width: `${duration > 0 ? (currentTime / duration) * 100 : 0}%`,
            }}
          ></div>
        </div>
        <div className="ml-2.5 text-sm">
          {isLoading ? "Loading..." : error ? "Error" : `${formatTime(currentTime)} / ${formatTime(duration)}`}
        </div>
      </div>
      {error && (
        <div className="mt-2 text-red-500 flex items-center">
          <AlertTriangle size={16} className="mr-2" />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

export default ResultCard;