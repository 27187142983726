import React, { useState, useEffect } from 'react';
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from '../firebase';
import ResultCard from './ResultCard';

const ExampleResultCard = ({ title, storagePath, backgroundColor, textColor, lyrics, tags }) => {
  const [audioUrl, setAudioUrl] = useState(null);
  const [error] = useState(null);

  useEffect(() => {
    const fetchAudioUrl = async () => {
      try {
        const url = await getDownloadURL(ref(storage, storagePath));
        setAudioUrl(url);
      } catch (error) {
        console.error("Error fetching audio URL:", error);
      }
    };

    fetchAudioUrl();
  }, [storagePath]);

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="w-full overflow-hidden">
      <ResultCard
        title={title}
        audioUrl={audioUrl}
        backgroundColor={backgroundColor}
        textColor={textColor}
        isPreview={true}
        hideDownload={true}
        lyrics={lyrics}
        tags={tags}
      />
    </div>
  );
};

export default ExampleResultCard;