import React, { createContext, useState, useContext, useEffect } from 'react';
import { auth } from '../firebase';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';

const AuthContext = createContext(null);
const AUTH_EMAIL_KEY = 'auth_email_for_sign_in';

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(null);
  const [emailLinkError, setEmailLinkError] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      // Only set loading false if we're not processing an email link
      if (!isSignInWithEmailLink(auth, window.location.href)) {
        setLoading(false);
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const handleEmailSignIn = async () => {
      if (!isSignInWithEmailLink(auth, window.location.href)) return;

      try {
        // Get email from URL parameters
        const urlParams = new URLSearchParams(window.location.search);
        let email = urlParams.get('email');

        // If no email in URL, try session storage
        if (!email) {
          email = sessionStorage.getItem(AUTH_EMAIL_KEY);
        }

        // If still no email, try localStorage as fallback for browser handover
        if (!email) {
          email = localStorage.getItem(AUTH_EMAIL_KEY);
        }

        if (email) {
          await signInWithEmailLink(auth, email, window.location.href);
          // Clean up storage
          sessionStorage.removeItem(AUTH_EMAIL_KEY);
          localStorage.removeItem(AUTH_EMAIL_KEY);
          // Redirect after successful sign in
          window.history.replaceState(null, '', '/generator');
        } else {
          setEmailLinkError('No email found for sign in. Please request a new sign in link.');
          console.error('No email found for sign in');
        }
      } catch (error) {
        console.error('Email sign in error:', error);
        setEmailLinkError('Failed to complete sign in. Please request a new sign in link.');
      } finally {
        setLoading(false);
      }
    };

    if (!user) {
      handleEmailSignIn();
    }
  }, [user]);

  const value = {
    user,
    loading,
    authError,
    emailLinkError,
    clearAuthError: () => {
      setAuthError(null);
      setEmailLinkError(null);
    }
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }
  return context;
};